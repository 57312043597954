// Firebase imports
import { db } from '../firebase/initFirebase'
import { DocumentReference, where } from 'firebase/firestore'
import {
    doc,
    getDoc,
    getDocs,
    collection,
    query,
    orderBy,
    DocumentSnapshot,
} from 'firebase/firestore'
// Types
import {
    Creator,
    MemoryShort,
    User,
    MemoryRefType,
    ObituaryRefType,
    Obituary,
    Suppression,
} from '../types/types'

// Get obituaries from references
const getObituaries = async (
    refs: Array<DocumentReference<ObituaryRefType> | Obituary>,
    getCreator = false,
    sortObits = true
) => {
    try {
        let obituaries = []
        // Check if memory has obituaries
        if (refs?.length !== 0) {
            // Get obituaries from references list in memory

            obituaries = await Promise.all(
                refs.map(
                    async (obituary: DocumentReference<ObituaryRefType>) => {
                        const obit: DocumentSnapshot<ObituaryRefType> =
                            await getDoc(obituary)
                        if (obit.data()) {
                            if (getCreator) {
                                // Get the creator of the obituary
                                const user: DocumentSnapshot<User> =
                                    await getDoc(obit.data().creator)
                                let creator: Creator
                                if (user.data().name) {
                                    creator = {
                                        cid: user.id,
                                        name: user.data().name,
                                    }
                                } else {
                                    creator = {
                                        cid: user.id,
                                        name: 'Ekkert nafn',
                                    }
                                }
                                // Get the short type of obit's memory
                                const memoryRef: DocumentSnapshot<MemoryRefType> =
                                    await getDoc(obit.data().memory)

                                let memory: MemoryShort = {
                                    mid: memoryRef.id,
                                    fullName: memoryRef.data().data.fullName,
                                    profileImg:
                                        memoryRef.data().data.profileImg,
                                    shareCard:
                                        memoryRef.data().shareCards.memory,
                                    birth: memoryRef.data().data.birth,
                                    death: memoryRef.data().data.death,
                                }
                                const obi = {
                                    oid: obit.id,
                                    ...obit.data(),
                                    text: JSON.parse(obit.data().text),
                                    creator,
                                    memory,
                                }

                                return obi
                            } else {
                                if (obit.data()) {
                                    const obi = {
                                        oid: obit.id,
                                        ...obit.data(),
                                        text: JSON.parse(obit.data().text),
                                    }

                                    return obi
                                }
                            }
                        } else {
                            return null
                        }
                    }
                )
            )
            obituaries = obituaries.filter((ob) => ob !== null)

            if (sortObits) {
                // Sort obituaries by date
                const sortedObits = obituaries.sort((x, y) => {
                    return y.date - x.date
                })
                return sortedObits
            }

            return obituaries
        }
        return []
    } catch (error) {
        console.error(error)
    }
}

// Get obituaries from memories subcollection
const getCondolences = async (mid: string) => {
    try {
        const condSnapshots = await getDocs(
            query(
                collection(db, `memories/${mid}/condolences`),
                orderBy('date', 'desc')
            )
        )

        const condolences = condSnapshots.docs.map((condolence) => {
            return { id: condolence.id, ...condolence.data() }
        })
        return condolences
    } catch (error) {
        console.error(error)
        return []
    }
}

const firestoreReadService = () => {
    return {
        // Get user data
        getUser: async (userID: string) => {
            try {
                const docRef = doc(db, 'users', userID)
                const user = await getDoc(docRef)
                //Do we need the id? Already in user object (uid)
                return { ...user.data() }
            } catch (error) {
                alert(error)
            }
        },
        // Get all memory documents
        getAllMemIDs: async () => {
            const querySnapshot = await getDocs(collection(db, 'memories'))
            const memories = []
            querySnapshot.forEach((doc) => {
                // doc.data() is never undefined for query doc snapshots
                memories.push({ id: doc.id })
            })

            return memories
        },
        getAllPrismicArticles: async () => {},
        // Get single memory by id
        getMemoryById: async (
            mid: string,
            getCreator = false,
            sortObits = true
        ) => {
            try {
                // Get the memory
                const memory = await getDoc(doc(db, 'memories', mid))
                if (memory?.data()) {
                    // Get condolences from memories subcollection
                    const condolences = await getCondolences(mid)
                    // Get obituaries from references
                    const obituaries = await getObituaries(
                        memory.data().obituaries,
                        getCreator,
                        sortObits
                    )

                    const bio = memory.data().data.biography
                    let bioText = bio ? JSON.parse(bio) : null

                    const family = memory.data().data.family
                    let familyText = family ? JSON.parse(family) : null

                    return {
                        mid: memory.id,
                        ...memory.data().data,
                        family: familyText,
                        biography: bioText,
                        invitedEmails: memory.data().invitedEmails,
                        condolences,
                        obituaries,
                        editors: memory.data().editors,
                        followers: memory.data().followers,
                        shareCards: memory.data().shareCards,
                    }
                }
                return null
            } catch (error) {
                console.error(error)
            }
        },

        // Get single Advice article by id
        getAdviceById: async (aid: string) => {
            try {
                // Get the advice
                const article = await getDoc(doc(db, 'advice', aid))

                return {
                    aid: article.id,
                    ...article.data(),
                }
            } catch (error) {
                console.error(error)
            }
        },
        // Get single obituary by oid
        getObituaryById: async (oid: string, getIDs = false) => {
            try {
                // Get obituary doc and return as an object
                const obituary = await getDoc(
                    doc(
                        db,
                        'obituaries',
                        oid
                    ) as DocumentReference<ObituaryRefType>
                )
                let mid: string
                let uid: string
                if (getIDs && obituary.data()) {
                    const ids = await Promise.all([
                        await getDoc(obituary?.data().memory),
                        await getDoc(obituary?.data().creator),
                    ])
                    mid = ids[0].id
                    uid = ids[1].id
                }
                return obituary?.data()
                    ? {
                          oid: obituary.id,
                          ...obituary.data(),
                          text: JSON.parse(obituary.data().text),
                          mid,
                          uid,
                      }
                    : null
            } catch (error) {
                alert(error)
            }
        },
        // Small function to check if obit exists
        checkIfObitExists: async (oid: string) => {
            const oRef = doc(db, 'obituaries', oid)

            const obit = await getDoc(oRef)

            const exists = obit?.data() ? true : false

            return exists
        },
        // get obituaries written by user
        getUsersObits: async (
            obitRefs: Array<DocumentReference<ObituaryRefType>>
        ) => {
            try {
                // Get obituaries from references
                const obituaries = await getObituaries(obitRefs, true)

                return obituaries
            } catch (error) {
                console.error(error)
            }
        },

        // get bookmarks by user
        getUsersBookmarks: async (
            bookRefs: Array<DocumentReference<ObituaryRefType>>
        ) => {
            try {
                if (bookRefs) {
                    let obituaries = []
                    // Get obituaries from references
                    obituaries = await getObituaries(bookRefs, true)

                    return obituaries
                } else {
                    return []
                }
            } catch (error) {
                console.error(error)
            }
        },

        // get user memories and for every memory get the memories obituaries
        getUsersMems: async (
            memRefs: Array<DocumentReference<MemoryRefType>>
        ) => {
            try {
                if (memRefs) {
                    // get memories
                    let memories = []
                    memories = await Promise.all(
                        // get all obituaries & condolences for each memory
                        memRefs?.map(
                            async (
                                memory: DocumentReference<MemoryRefType>
                            ) => {
                                const mem: DocumentSnapshot<MemoryRefType> =
                                    await getDoc(memory)

                                // Get obituaries from memories refs
                                const obituaries = await getObituaries(
                                    mem.data()?.obituaries || []
                                ).catch((err) =>
                                    console.error(
                                        'getting obituaries failed',
                                        err
                                    )
                                )

                                // Get condolences from memories subcollection
                                const condolences = await getCondolences(
                                    mem.id
                                ).catch((err) =>
                                    console.error(
                                        'getting condolences failed',
                                        err
                                    )
                                )

                                const bio = mem.data().data.biography
                                let bioText: JSX.Element[] = bio
                                    ? JSON.parse(bio)
                                    : null

                                const retMemory = {
                                    mid: mem.id,
                                    ...mem.data().data,
                                    biography: bioText,
                                    obituaries,
                                    condolences,
                                }

                                return retMemory
                            }
                        )
                    )
                    return memories
                } else {
                    return []
                }
            } catch (error) {
                console.error(error)
            }
        },
        getFollowedMems: async (uid: string, page: number, limit: number) => {
            const q = query(
                collection(db, 'memories'),
                where('followers', 'array-contains-any', [uid])
            )

            const startIndex = (page - 1) * limit
            const endIndex = page * limit

            const querySnapshot = await getDocs(q)

            let followedMems = []

            querySnapshot.forEach((doc) => {
                followedMems.push({
                    ...doc.data(),
                    ...doc.data().data,
                    mid: doc.id,
                    readOnly: true,
                })
            })

            return followedMems.slice(startIndex, endIndex)
        },
        // Get suppression groups from sendGrid for a specific user
        getUsersSettings: async (user: User) => {
            const res = await fetch(`/api/auth/emailGroups/${user.email}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    Accept: 'application/json',
                },
            }).then(async (res) => await res.json())
            if (res.success) {
                let obitAnnouncements = null
                let condAnnouncements = null
                let mailAnnouncements = null
                let annoAnnouncements = null

                res.suppressions.map((s: Suppression) => {
                    if (s.id === 181157) obitAnnouncements = !s.suppressed
                    else if (s.id === 181346) condAnnouncements = !s.suppressed
                    else if (s.id === 184091) mailAnnouncements = !s.suppressed
                    else if (s.id === 192000) annoAnnouncements = !s.suppressed
                })
                return {
                    obitAnnouncements,
                    condAnnouncements,
                    mailAnnouncements,
                    annoAnnouncements,
                }
            } else {
                return null
            }
        },
        getUploadURL: async () => {
            try {
                return fetch('/api/video/upload', {
                    method: 'GET',
                })
                    .then((res) => res.json())
                    .then((data) => {
                        return data
                    })
            } catch (e) {
                console.error('Error in createUpload', e)
                return null
            }
        },
    }
}
export default firestoreReadService()
