// 3rd party libraries
import { motion } from 'framer-motion'
// Styles
import s from './Footer.module.scss'
import c from 'classnames'
// Components
import { Container } from '../../core/container/Container'
import { Link } from '../../core/link/Link'
import { Text } from '../../core/text/Text'
import { LinkButton } from '../../core/linkButton/LinkButton'
import { usePlausible } from 'next-plausible'

export const Footer = () => {
    const plausible = usePlausible()

    return (
        <motion.footer layout className={s.footer}>
            <Container className={s.container}>
                <div className={s.line} />

                <svg
                    className={c(s.logo, s.icon)}
                    width="54"
                    height="54"
                    viewBox="0 0 54 54"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        d="M27 0C12.111 0 0 12.111 0 27C0 41.889 12.111 54 27 54C41.889 54 54 41.889 54 27C54.0156 12.111 41.9046 0 27 0ZM50.4884 35.3653C49.2399 37.8936 46.6647 39.2046 43.4341 39.2046C40.1098 39.2046 37.5659 37.0353 35.0844 34.304C37.0353 35.1624 39.2827 35.7399 41.811 35.7399C45.9156 35.7399 49.0526 34.1012 51.1283 30.8861C51.378 30.5116 51.5809 30.137 51.7682 29.7624C51.815 31.2295 51.3 33.7266 50.4884 35.3653ZM4.57283 24.2532C6.46127 21.7873 9.52023 20.4295 13.141 20.4295C15.9035 20.4295 18.026 21.3971 19.8832 22.7081C19.1965 22.5988 18.4786 22.5364 17.7295 22.5364C7.47572 22.5364 3.96416 29.8249 3.18381 34.3821C2.94971 33.6173 2.74682 32.8214 2.57514 32.0254C2.2474 29.2162 2.96532 26.3445 4.57283 24.2532ZM48.9434 15.1699C49.7861 16.7306 50.4728 18.385 50.9723 20.1173C52.0179 23.9254 51.5341 27.6243 49.5832 30.4023C47.8509 32.8682 45.1353 34.2884 42.1075 34.2884C39.0329 34.2884 35.9428 33.1647 32.9306 30.9642C33.6642 31.1046 34.3977 31.1671 35.1312 31.1671C41.4676 31.1671 44.8387 27.7335 46.5399 24.8462C48.5688 21.4127 49.2243 17.5578 48.9434 15.1699ZM27 2.07572C33.0243 2.07572 38.5647 4.22948 42.8723 7.80347C46.8988 11.1902 46.9925 16.8399 44.9948 21.7093C43.1688 26.1416 39.5012 28.904 35.4277 28.904C32.2751 28.904 29.4347 27.7491 26.4694 25.2832L25.9543 24.8619C22.5364 21.9902 17.8543 18.0416 12.2358 18.0416C6.88266 18.0416 3.93295 20.726 2.29422 23.5353C4.01098 11.4243 14.4364 2.07572 27 2.07572ZM27 51.9399C18.2913 51.9399 10.5971 47.4451 6.14913 40.6561C6.16474 40.6561 6.16474 40.6561 6.16474 40.6561C4.0422 37.5971 4.99422 33.2428 6.72659 29.8873C8.14682 27.1092 11.5179 23.7382 17.2769 23.7382C22.5988 23.7382 26.4381 28.3578 30.1526 32.8214C33.7734 37.1601 38.0341 41.6393 43.0283 41.6393C45.3381 41.6393 46.6803 41.3896 47.726 40.859C43.2468 47.5231 35.6306 51.9399 27 51.9399Z"
                        fill="#9A4534"
                    />
                </svg>

                <Text variant="h1" tag="h2" align="center" className={s.logo}>
                    Við erum til staðar
                </Text>
                <Text variant="p-18" align="center" className={s.logo}>
                    Ef spurningar vakna er velkomið að skrifa okkur. Við erum
                    stöðugt að þróa vefinn og tökum því einnig gjarnan á móti
                    ábendingum. Hafðu samband á{' '}
                    <a href="mailto:minningar@minningar.is">
                        minningar@minningar.is
                    </a>
                </Text>
                <div className={s.links}>
                    <div className={c(s.links__container, s.infoPage)}>
                        <Link
                            to="/about-us"
                            onClick={() => plausible('footer-umOkkur-link')}
                        >
                            <Text variant="p-18">Um okkur</Text>
                        </Link>
                    </div>
                    <div className={c(s.links__container, s.services)}>
                        <Link
                            to="/suggestions/form"
                            // onClick={() =>
                            //     plausible('footer-services-link')
                            // }
                        >
                            <Text variant="p-18">Senda ábendingu</Text>
                        </Link>
                    </div>
                    <div className={s.links__buttons}>
                        <LinkButton
                            url="https://www.youtube.com/channel/UCFld7w2Yg9zO3-0hLEyFmrw"
                            icon="youtube2"
                        />
                        <LinkButton
                            url="https://facebook.com/minningar.is"
                            icon="facebook"
                        />
                        <LinkButton
                            url="https://www.instagram.com/minningar.is"
                            icon="instagram"
                        />
                    </div>
                    <div className={c(s.links__container, s.aboutUs)}>
                        <Link
                            to="/terms-of-use"
                            onClick={() =>
                                plausible('footer-perssonuvernd-link')
                            }
                        >
                            <Text variant="p-18">Notendaskilmálar</Text>
                        </Link>
                    </div>
                    <div className={c(s.links__container, s.policy)}>
                        <Link
                            to="/privacy-policy"
                            onClick={() =>
                                plausible('footer-personustefna-link')
                            }
                        >
                            <Text variant="p-18">Persónuverndarstefna</Text>
                        </Link>
                    </div>
                    <div className={c(s.links__container, s.copyright)}>
                        <Text variant="p-18">©2021 Minningar</Text>
                    </div>
                </div>
                <img
                    alt=""
                    className={s.background}
                    src="/images/minningar_bak_fotur.jpg"
                />
            </Container>
        </motion.footer>
    )
}
