// React&NextJS
import { useState } from 'react'
// 3rd party libraries
// Styles
import s from './NavSearch.module.scss'
import c from 'classnames'
// Components
import { Icon } from '../../core/icon/Icon'
import router from 'next/router'
import { useKeyDown } from '../../../hooks/useKeyDown'

const NavSearch = ({ ...props }) => {
    const [localQuery, setLocalQuery] = useState('')
    const [isLoading, setIsLoading] = useState(false)
    const [isFocused, setIsFocused] = useState(false)
    // const plausible = usePlausible()

    useKeyDown(['Enter'], ({ key }) => {
        if (key === 'Enter' && isFocused) {
            setIsLoading(true)

            const ret = router.push(`/memories?name=${localQuery}`)
            ret.then(() => {
                setLocalQuery('')
                setIsLoading(false)
                setIsFocused(false)
            })
        }
    })

    return (
        router?.router?.route !== '/memories' && (
            <div
                className={c(
                    s.searchBar,
                    props.className,
                    isLoading && s.loading,
                    isFocused && s.focus
                )}
            >
                <div className={s.name}>
                    <Icon variant="search" />

                    <input
                        type="text"
                        id="search-input"
                        placeholder="Hvern viltu finna?"
                        className={s.name_input}
                        value={localQuery}
                        onFocus={() => setIsFocused(true)}
                        onBlur={() => setIsFocused(false)}
                        onChange={(e) => {
                            setLocalQuery(e.target.value)
                        }}
                    />
                </div>
            </div>
        )
    )
}

export default NavSearch
