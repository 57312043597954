// React&NextJS
import React, { useRef, useState } from 'react'
// Context&Auth
import { useAuth } from '../../../context/UserContext'
// Services&Helper functions
import useOutsideClick from '../../../hooks/useOutsideClick'
// Styles
import s from './AccountDropdown.module.scss'
import c from 'classnames'
// Components
import { Button } from '../../core/button/Button'
import { usePlausible } from 'next-plausible'

export const AccountDropDown = ({
    className,
    children,
}: {
    children: React.ReactNode
    className?: string
}) => {
    const [isOpen, setOpen] = useState(false)
    const { logout } = useAuth()
    const plausible = usePlausible()

    const ref = useRef()

    useOutsideClick(ref, () => {
        setOpen(false)
    })

    return (
        <div
            className={c(s.container, className, isOpen && s.open)}
            onClick={() => setOpen((prev) => !prev)}
            ref={ref}
        >
            {children}
            <div className={c(s.buttonContainer)}>
                <Button
                    variant="text"
                    className={s.button}
                    to="/account"
                    onClick={() => plausible('nav-minarSidur-button')}
                >
                    Mitt svæði
                </Button>
                <Button
                    variant="text"
                    className={s.button}
                    onClick={() => {
                        logout()
                        plausible('nav-utskra-button')
                    }}
                >
                    Útskrá
                </Button>
            </div>
        </div>
    )
}
