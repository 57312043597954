// React&NextJS
import React, { Children, useContext, cloneElement, useEffect } from 'react'
// Context&Auth
import { UIContext } from '../../../context/UIContext'
// Styles
import s from './Nav.module.scss'
import classnames from 'classnames/bind'
import { LinkButton } from '../../core/linkButton/LinkButton'
import { motion, useAnimation } from 'framer-motion'

const c = classnames.bind(s)

type IProps = {
    children: React.ReactNode
}

export const Nav = ({ children }: IProps) => {
    const { uiState, setUIState } = useContext(UIContext)

    const handleClose = () => {
        setUIState({ isNavOpen: false })
    }

    // aria-expanded={navOpen}: removed for now as nav is only hidden in mobile; UIContext is unaware of this distinction

    const textAnimationControls = useAnimation()
    const variants = {
        visible: {
            opacity: 1,
            transition: {
                type: 'tween',
                duration: 0.55,
                delay: 0.25 + 0.15,
            },
        },
        hidden: {
            opacity: 0,

            transition: {
                type: 'tween',

                delay: 0,
            },
        },
    }

    useEffect(() => {
        if (uiState.isNavOpen) {
            textAnimationControls.start('visible')
        } else {
            textAnimationControls.start('hidden')
        }
    }, [uiState.isNavOpen])

    return (
        <nav
            className={c({ nav: true, open: uiState.isNavOpen })}
            aria-label="Main Navigation"
        >
            <div className={s.nav__inner}>
                <div className={s.nav__content}>
                    <ul className={s.nav__list}>
                        {Children.map(children, (child, i) => (
                            <li className={s.nav__item} key={i}>
                                {cloneElement(child as React.ReactElement, {
                                    tabIndex: 0,
                                })}
                            </li>
                        ))}
                        <motion.div
                            animate={textAnimationControls}
                            variants={variants}
                            className={s.navlinkMotion}
                        >
                            <li className={s.nav__list__buttons}>
                                <LinkButton
                                    url="https://facebook.com/minningar.is"
                                    icon="facebook"
                                    color="white"
                                />
                                <LinkButton
                                    url="https://www.instagram.com/minningar.is"
                                    icon="instagram"
                                    color="white"
                                />
                            </li>
                        </motion.div>
                    </ul>
                </div>
            </div>
            <button
                className={s.nav__backdrop}
                aria-label="Close Main Navigation"
                onClick={handleClose}
            />
        </nav>
    )
}
