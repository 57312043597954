import { storage } from '../firebase/initFirebase'
import {
    getDownloadURL,
    ref,
    uploadBytes,
    uploadBytesResumable,
} from 'firebase/storage'
import uuid from 'react-uuid'
import { PlateObj } from '../types/types'

const imageHelperFunctions = () => {
    return {
        // Upload an image to the folder specified in storage
        handleUpload: async (
            mainImg: File,
            folder: string,
            id: string,
            oos = false
        ) => {
            const path = `${folder}/${id}`
            const filePath = oos
                ? `${path}_salmaskra`
                : `${path}_${uuid().substring(0, 4)}`

            const storageRef = ref(storage, filePath)
            return uploadBytes(storageRef, mainImg).then((image) => {
                return filePath
            })
        },
        handleVideoUpload: async (video: File, folder: string, id: string) => {
            const path = `${folder}/${id}`
            const filePath = `${path}_${uuid().substring(0, 4)}`

            const storageRef = ref(storage, filePath)

            const uploadTask = uploadBytesResumable(storageRef, video)

            uploadTask.on(
                'state_changed',
                (snapshot) => {
                    // Observe state change events such as progress, pause, and resume
                    // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
                    const progress =
                        (snapshot.bytesTransferred / snapshot.totalBytes) * 100
                    console.log('Upload is ' + progress + '% done')
                    switch (snapshot.state) {
                        case 'paused':
                            console.log('Upload is paused')
                            break
                        case 'running':
                            console.log('Upload is running')
                            break
                    }
                },
                (error) => {
                    // Handle unsuccessful uploads
                }
            )

            return uploadTask.then(async (state) => {
                if (state.state === 'success') {
                    const link = await getDownloadURL(
                        uploadTask.snapshot.ref
                    ).then((downloadURL) => {
                        console.log('File available at', downloadURL)
                        return downloadURL
                    })
                    return link
                }
            })
        },

        // Get the url of image using its filepath in storage
        getStorageURL: async (filePath: string) => {
            const imgRef = ref(storage, filePath)
            return getDownloadURL(imgRef)
                .then((url) => {
                    return url
                })
                .catch((err) => {
                    console.error('Error:\n', err)
                })
        },

        // checkIfOverLimit. Ekki notað. Þurfum að samþykja allar myndir.
        // Möguleiki á að nota virkni í feature featuses.
        checkIfOverLimit: (files: FileList | File[], name: string) => {
            if (files?.length > 0) {
                if (name === 'mainImg') {
                    const mainImg = files[0]
                    const imgSize = mainImg.size / 10 ** 6
                    if (imgSize >= 10) return false
                } else {
                    for (let img = 0; img < files.length; img++) {
                        const mainImg = files[img]
                        const imgSize = mainImg.size / 10 ** 6

                        if (imgSize >= 10) return false
                    }
                }
            }
            return true
        },

        // Check pixels. Ekki notað. Þurfum að samþykja allar myndir.
        // Möguleiki á að nota virkni í feature featuses.
        checkPixels: (
            galleryLength?: number,
            galleryPrev?: Array<HTMLImageElement>
        ) => {
            if (galleryLength > 0) {
                if (galleryLength > 10) return false
                const arr = galleryPrev.map((img) => {
                    return imageHelperFunctions().checkImgDimension(img)
                })
                return !arr.some((x) => x === false)
            }
            return true
        },
        checkImgDimension: (img: HTMLImageElement) => {
            // image needs to have minimum length or height 500px and minimum 200px both sides.
            const hasMinDimension = img.height >= 200 && img.width >= 200
            const has500Side = img.height >= 500 || img.width >= 500
            return hasMinDimension && has500Side
        },
        checkIfImage: (files: FileList | File[], name: string) => {
            if (files?.length > 0) {
                if (name === 'mainImg') {
                    const mainImg = files[0]

                    return (
                        mainImg.type.includes('image/jpg') ||
                        mainImg.type.includes('image/jpeg') ||
                        mainImg.type.includes('image/png') ||
                        mainImg.type.includes('image/gif')
                    )
                } else {
                    for (let img = 0; img < files.length; img++) {
                        const mainImg = files[img]

                        if (
                            !(
                                mainImg.type.includes('image/jpg') ||
                                mainImg.type.includes('image/jpeg') ||
                                mainImg.type.includes('image/png') ||
                                mainImg.type.includes('image/gif')
                            )
                        )
                            return false
                    }
                }
            }
            return true
        },
        validateFiles: (
            // files used to check image size (.MB)
            files?: FileList | File[],
            name?: string,
            galleryLength?: number,
            // gallery of type img use to check image dimensions
            galleryPrev?: Array<HTMLImageElement>
        ) => {
            if (files?.length > 0) {
                const isUnderLimit = imageHelperFunctions().checkIfOverLimit(
                    files,
                    name
                )
                const isImage = imageHelperFunctions().checkIfImage(files, name)
                // const isPixels = galleryPrev
                //     ? imageHelperFunctions().checkPixels(
                //           galleryLength,
                //           galleryPrev
                //       )
                //     : true

                // return {
                //     isUnderLimit: true,
                //     isImage: isImage,
                //     isPixels: true,
                // }

                return { isImage: isImage, isUnderLimit: isUnderLimit }
            }
            return { isImage: true, isUnderLimit: true }
        },
        // Function which validates each image in the gallery
        validateUpload: (files: File[]) => {
            let errorMsg = ''
            if (files?.length > 0) {
                // Helper function that returns true if valid, else false
                const { isImage, isUnderLimit } =
                    imageHelperFunctions().validateFiles(files, 'gallery')
                if (!isImage) {
                    errorMsg =
                        'Myndir verða að vera af týpunni .jpeg/.jpg/.png/.gif'
                } else if (!isUnderLimit) {
                    errorMsg = 'Myndir verða að vera undir 10MB'
                }
                return { isValid: isImage && isUnderLimit, errorMsg }
            }
            return { isValid: true, errorMsg: '' }
        },
    }
}
export default imageHelperFunctions()
