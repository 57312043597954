// React&NextJS
import { useRouter } from 'next/router'
// Components
import { Link, LinkProps } from '../../core/link/Link'

export const NavLink = ({ to, children }: LinkProps) => {
    const router = useRouter()

    return (
        <Link
            to={to}
            aria-current={router.pathname === to ? 'page' : undefined}
            tabIndex={0}
        >
            {children}
        </Link>
    )
}
