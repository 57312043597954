// React&NextJS
import React, { useEffect, useContext, useState } from 'react'
import { useRouter } from 'next/router'
// Context&Auth
import { UIContext } from '../../../context/UIContext'
// Styles
import s from './Layout.module.scss'
import c from 'classnames'
// Components
import BugReport from '../bugReport/BugReport'
import { Footer } from '../footer/Footer'
import { Header } from '../header/Header'
import { NavContainer as Nav } from '../nav/NavContainer'

type IProps = {
    children: React.ReactNode
    preview?: boolean
}

const Layout = ({ children }: IProps) => {
    const router = useRouter()
    const { uiState, setUIState } = useContext(UIContext)
    const [noFooter, setNoFooter] = useState(false)

    useEffect(() => {
        setUIState({ isNavOpen: false })
        if (
            router.pathname.includes('/account') ||
            window.location.href.indexOf('form') > -1
        ) {
            setNoFooter(true)
        } else {
            setNoFooter(false)
        }
    }, [router])

    useEffect(() => {
        const scrollEl = document.createElement('div')
        scrollEl.style.cssText =
            'position:absolute;top:-9999px;width:50px;height:50px;overflow:scroll;'
        document.body.appendChild(scrollEl)
        const scrollbarWidth =
            scrollEl.getBoundingClientRect().width - scrollEl.clientWidth
        document.body.removeChild(scrollEl)
        document.documentElement.style.setProperty(
            '--browser-scrollbar-width',
            scrollbarWidth + 'px'
        )
    }, [])

    return (
        <>
            <div className={c(s.layout, { [s.navOpen]: uiState.isNavOpen })}>
                <Header>
                    <Nav />
                </Header>
                {/* <Header /> */}
                {/* ID for skip link */}
                <main id="main" className={s.layout__content}>
                    {children}
                </main>
                <BugReport />
                {!noFooter && <Footer />}
            </div>
        </>
    )
}

export default Layout
