// container Nav component, any data for nav structure should go here
// container gets imported into 'components/layout/Layout'

// React&NextJS
import { useContext, useEffect } from 'react'
// Context&Auth
import { UIContext } from '../../../context/UIContext'
// 3rd party libraries
import { motion, useAnimation } from 'framer-motion'
// Styles
import s from './NavContainer.module.scss'
// Components
import { Nav } from './Nav'
import { NavLink } from './NavLink'
import { Text } from '../../core/text/Text'
import { usePlausible } from 'next-plausible'

const MotionDiv = (label: string, to: string, nr: Number) => {
    const { uiState } = useContext(UIContext)
    const textAnimationControls = useAnimation()
    const plausible = usePlausible()

    const variants = {
        visible: (i: number) => ({
            transform: 'translateY(0%)',
            transition: {
                type: 'tween',
                duration: 0.55,
                delay: 0.25 + 0.15 * i,
            },
        }),
        hidden: {
            transform: 'translateY(130%)',
            transition: {
                type: 'tween',

                delay: 0,
            },
        },
    }

    useEffect(() => {
        if (uiState.isNavOpen) {
            textAnimationControls.start('visible')
        } else {
            textAnimationControls.start('hidden')
        }
    }, [uiState.isNavOpen])

    return (
        <NavLink to={to} onClick={() => plausible(`navMenu-${to}-button`)}>
            <div className={s.overflow}>
                <motion.div
                    initial="hidden"
                    animate={textAnimationControls}
                    variants={variants}
                    custom={nr}
                    className={s.navlinkMotion}
                >
                    <Text className={s.navlink} tag="h3" variant="h1">
                        {label}
                    </Text>
                </motion.div>
            </div>
        </NavLink>
    )
}

export const NavContainer = () => {
    return (
        <Nav>
            {MotionDiv('Minningarsíður', '/memories', 0)}
            {MotionDiv('Nýleg andlát', '/recently-deceased', 1)}
            {MotionDiv('Útfarir', '/recent-funerals', 2)}
            {MotionDiv('Hlaðvarp', '/podcast', 3)}
            {MotionDiv('Þjónustusíða', '/services', 4)}
            {MotionDiv('Fræðsluefni', '/articles', 5)}
        </Nav>
    )
}
