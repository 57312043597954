import Hls from 'hls.js'
import { useEffect, useRef } from 'react'

import s from './VideoPlayer.module.scss'
import mux from 'mux-embed'

const VideoPlayer = ({ src, children = null }) => {
    const videoRef = useRef(null)
    const initTime = Date.now()

    useEffect(() => {
        let hls

        if (videoRef.current) {
            const video = videoRef.current

            if (video.canPlayType('application/vnd.apple.mpegurl')) {
                // Some browers (safari and ie edge) support HLS natively
                video.src = src
            } else if (Hls.isSupported()) {
                // This will run in all other modern browsers
                hls = new Hls()
                hls.loadSource(src)
                hls.attachMedia(video)
            } else {
                console.error(
                    "This is a legacy browser that doesn't support MSE"
                )
            }

            mux.monitor(video, {
                debug: false,
                // pass in the 'hls' instance and the 'Hls' constructor
                hlsjs: hls,
                Hls,
                data: {
                    env_key: process.env.NEXT_PUBLIC_ENV, // required
                    // Metadata fields
                    player_name: 'Main Player', // any arbitrary string you want to use to identify this player
                    player_init_time: initTime,
                    video_id: src, // ex: 'abcd123'
                    video_title: src, // ex: 'My Great Video'
                    video_series: 'Obituary', // ex: 'Weekly Great Videos'
                },
            })
        }

        return () => {
            if (hls) {
                hls.destroy()
            }
        }
    }, [videoRef])

    if (videoRef) {
        return (
            <video
                ref={videoRef}
                src={src}
                contentEditable={false}
                controls={true}
                onContextMenu={(e) => e.preventDefault()}
                style={{ userSelect: 'none' }}
                className={s.obituaryVideo}
            >
                {children}
            </video>
        )
    }
}

export default VideoPlayer
