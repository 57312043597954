import React, { useEffect } from 'react'

// Context&Auth
import { AuthProvider } from '../context/UserContext'
import { UIProvider } from '../context/UIContext'
// 3rd party libraries
import { AnimateSharedLayout } from 'framer-motion'
import TagManager from 'react-gtm-module'
// import { Integrations } from '@sentry/tracing'
// import useSystemTheme from 'react-use-system-theme'
// Styles
import '../styles/global.scss'
// Components
import { Devtools } from '../components/components/devtools/Devtools'
import Layout from '../components/components/layout/Layout'
import Meta from '../components/meta/Meta'
// import Pixel from '../facebook'

const tagManagerArgs = {
    gtmId: process.env.NEXT_PUBLIC_GOOGLE_TAG_MANAGER_ID,
}

function MyApp({ Component, pageProps }) {
    const isDev = process.env.NODE_ENV === 'development'
    // const systemTheme = useSystemTheme()
    let systemTheme = 'light'

    useEffect(() => {
        TagManager.initialize(tagManagerArgs)
    }, [])

    return (
        <>
            <Meta systemTheme={systemTheme} />
            {/* <Pixel /> */}
            <AnimateSharedLayout>
                <AuthProvider>
                    <UIProvider>
                        <Layout preview={pageProps?.preview ?? false}>
                            <Component {...pageProps} />
                        </Layout>
                    </UIProvider>
                </AuthProvider>
            </AnimateSharedLayout>
            {isDev && <Devtools />}
        </>
    )
}

export default MyApp
