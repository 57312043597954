// Firebase imports
import { db } from '../firebase/initFirebase'
import { doc, updateDoc, arrayRemove, deleteDoc } from 'firebase/firestore'
import { Obituary, Condolence, ObituaryPlate } from '../types/types'
import { deleteObject, getStorage, ref } from '@firebase/storage'

const firestoreDeleteService = () => {
    return {
        // Delete obituary
        deleteObituary: async (
            o: ObituaryPlate | Obituary,
            mid: string | null = null
        ) => {
            try {
                const storage = getStorage()

                const oRef = doc(db, 'obituaries', o.oid)
                //If we don't have the creator reference, we use the id
                const userRef = o.uid
                    ? doc(db, 'users', o.uid)
                    : doc(db, 'users', o.creator.cid)
                //If we dont have the ref of the memory, we pass in the id of it
                const mRef = mid ? doc(db, 'memories', mid) : o.memory

                await Promise.all([
                    // Remove obituary ref from memory
                    updateDoc(mRef, {
                        obituaries: arrayRemove(oRef),
                    }).catch((err) =>
                        console.error(
                            'updating memory obituary doc failed: ',
                            err
                        )
                    ),
                    // Remove obituary ref from user
                    updateDoc(userRef, {
                        obituaries: arrayRemove(oRef),
                    }).catch((err) =>
                        console.error(
                            'updating user obituary doc failed: ',
                            err
                        )
                    ),
                    // remove obituary images
                    o.gallery.map(async (image) => {
                        const imageRef = ref(storage, image)
                        await deleteObject(imageRef).catch((err) =>
                            console.error('removing image failed: ', err)
                        )
                    }),

                    // Remove videos from MUX
                    o.videos.map(async (vid) => {
                        await fetch('/api/video', {
                            method: 'DELETE',
                            body: JSON.stringify({
                                assetID: vid.assetID,
                            }),
                        })
                    }),
                ])
                // Remove the obituary
                await deleteDoc(oRef).catch((err) =>
                    console.error('removing obituary doc failed: ', err)
                )
            } catch (error) {
                console.error(error)
            }
        },
        // Delete condolence
        deleteCondolence: async (c: Condolence, mid: string) => {
            const cRef = doc(db, `memories/${mid}/condolences`, c.id)
            await deleteDoc(cRef)
        },
        removeFromMUX: async (videoData: {
            assetID: string
            displayID: string
        }) => {
            const res = await fetch('/api/video', {
                method: 'DELETE',
                body: JSON.stringify({
                    assetID: videoData.assetID,
                }),
            })
            if (res.ok) {
                console.log('Deleted from MUX.')
            }
        },
        unfollowMemory: async (mid: string, uid: string) => {
            const mRef = doc(db, 'memories', mid)
            // Remove obituary ref from memory
            updateDoc(mRef, {
                followers: arrayRemove(uid),
            }).catch((err) =>
                console.error('updating memory obituary doc failed: ', err)
            )
        },
        // Delete request
        // TODO: Biðja um aðgang
        // deleteRequest: async (user: User, reqIndex: number) => {
        //     const uRef = doc(db, 'users', user.uid)
        //     const reqs = user.requests
        //     reqs.received.splice(reqIndex, 1)
        //     await updateDoc(uRef, {
        //         requests: reqs,
        //     })
        // },

        // // Delete sendgrid email
        // deleteSendgridEmail: async (email: string) => {

        //     const emails = await fetch(
        //         'https://api.sendgrid.com/v3/marketing/contacts/search/emails',
        //         {
        //             method: 'POST',
        //             headers: {
        //                 Authorization:
        //                     'Bearer ' +
        //                     process.env.NEXT_PUBLIC_SENDGRID_API_KEY,
        //                 'Content-Type': 'application/json',
        //             },
        //             body: JSON.stringify({
        //                 emails: [email],
        //             }),
        //         }
        //     ).then(async (res) => await res.json())

        //     const { id } = emails.result[email].contact

        //     const ress = await fetch(
        //         'https://api.sendgrid.com/v3/marketing/contacts?ids=' + id,
        //         {
        //             method: 'DELETE',
        //             headers: {
        //                 Authorization:
        //                     'Bearer ' +
        //                     process.env.NEXT_PUBLIC_SENDGRID_API_KEY,
        //                 'Content-Type': 'application/json',
        //             },
        //         }
        //     )
        //     const response = await ress.json()
        //     return response
        // },
    }
}
export default firestoreDeleteService()
