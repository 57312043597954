// @ts-nocheck
// 3rd party libraries
import {
    createBlockquotePlugin,
    createDeserializeDocxPlugin,
    createDndPlugin,
    createExitBreakPlugin,
    createHeadingPlugin,
    createItalicPlugin,
    createKbdPlugin,
    createNodeIdPlugin,
    createNormalizeTypesPlugin,
    createParagraphPlugin,
    createPlateUI,
    createPluginFactory,
    createPlugins,
    // createSelectOnBackspacePlugin,
    createSoftBreakPlugin,
    ELEMENT_H1,
    ELEMENT_H2,
    ELEMENT_H3,
    ELEMENT_H4,
    ELEMENT_H5,
    ELEMENT_H6,
    ELEMENT_IMAGE,
    // ELEMENT_MEDIA_EMBED,
    ELEMENT_PARAGRAPH,
    getRootProps,
    ImagePlugin,
    KEYS_HEADING,
    StyledElement,
    StyledElementProps,
    withImageUpload,
} from '@udecode/plate'
import { withProps } from '@udecode/plate-common'
import {
    ELEMENT_EXCALIDRAW,
    ExcalidrawElement,
} from '@udecode/plate-ui-excalidraw'
import { withStyledDraggables } from './live/config/components/withStyledDraggables'
import { withStyledPlaceHolders } from './live/config/components/withStyledPlaceHolders'
import s from './Plate.module.scss'
import c from 'classnames'
import { CONFIG } from './live/config/config'
import VideoPlayer from '../videoPlayer/VideoPlayer'

export const ELEMENT_VIDEO = 'video'

export const createElement = (
    text = '',
    {
        type = ELEMENT_PARAGRAPH,
        mark,
    }: {
        type?: string
        mark?: string
    } = {}
) => {
    const leaf = { text }
    if (mark) {
        leaf[mark] = true
    }

    return {
        type,
        children: [leaf],
    }
}

// example: pressing enter at the end of a h1 block changes the format to p in the next block
export const optionsExitBreakPlugin = {
    rules: [
        {
            hotkey: 'mod+enter',
        },
        {
            hotkey: 'mod+shift+enter',
            before: true,
        },
        {
            hotkey: 'enter',
            query: {
                start: true,
                end: true,
                allow: KEYS_HEADING,
            },
        },
    ],
}

// Custom plate element for videos
const StyledElementVideo = (props: StyledElementProps) => {
    const { children, nodeProps } = props

    const rootProps = getRootProps(props)

    return (
        <div {...nodeProps} {...rootProps}>
            <VideoPlayer src={props.element.url}>{children}</VideoPlayer>
            {/* <video
                src={props.element.url}
                ref={videoRef}
                {...rootProps}
                onLoad={() => console.log('load')}
                contentEditable={false}
                style={{ userSelect: 'none' }}
            >
                {children}
            </video> */}
        </div>
    )
}

let draftComponents = createPlateUI({
    [ELEMENT_EXCALIDRAW]: ExcalidrawElement,
    [ELEMENT_H1]: withProps(StyledElement, {
        as: 'h6',
    }),
    [ELEMENT_H2]: withProps(StyledElement, {
        as: 'h6',
    }),
    [ELEMENT_H3]: withProps(StyledElement, {
        as: 'h6',
    }),
    [ELEMENT_H4]: withProps(StyledElement, {
        as: 'h6',
    }),
    [ELEMENT_H5]: withProps(StyledElement, {
        as: 'h6',
    }),
    [ELEMENT_H6]: withProps(StyledElement, {
        as: 'h6',
    }),
    [ELEMENT_PARAGRAPH]: withProps(StyledElement, {
        as: 'p',
    }),
    [ELEMENT_VIDEO]: withProps(StyledElementVideo, {
        as: 'video',
        // src: 'https://firebasestorage.googleapis.com/v0/b/minning-f44b2.appspot.com/o/videos%2Fvideos_35bb?alt=media&token=53ffa40a-fbe0-4871-8d44-92964646fb58',
        controls: true,
        // controlsList: 'nodownload noplaybackrate',
        // disablePictureInPicture: true,
        // Classname used to inject src
        className: c('obitVid', s.obituaryVideo),
        onContextMenu: (e) => e.preventDefault(),
    }),
    // customize your components by plugin key
})

let components = withStyledPlaceHolders(draftComponents)
components = withStyledDraggables(components)

export const comps = components

const createMediaEmbedPlugin = createPluginFactory({
    key: ELEMENT_VIDEO,
    isElement: true,
    isVoid: true,

    then: (editor, { type }) => {
        // used when deserialising html (injecting src)

        return {
            deserializeHtml: {
                rules: [
                    {
                        validNodeName: 'video',
                    },
                ],
                getNode: (el: HTMLElement) => {
                    const url = el.getAttribute('src')
                    console.log('srcNATION')

                    if (url) {
                        return {
                            type,
                            url,
                        }
                    }
                },
            },
        }
    },
})

export const createImagePlugintest = createPluginFactory<ImagePlugin>({
    key: ELEMENT_IMAGE,
    isElement: true,
    isVoid: true,
    withOverrides: withImageUpload,
    then: (editor, { type }) => ({
        deserializeHtml: {
            rules: [
                {
                    validNodeName: 'IMG',
                },
            ],
            getNode: (el) => ({
                type,
                url: el.getAttribute('src'),
            }),
        },
    }),
})

// If we want to support images inside obituaries
// const imageUpload = async (i: string | ArrayBuffer) => {
//     console.log('uploading')

//     return 'string'
// }

const bioComponents = withStyledPlaceHolders(draftComponents)

export const pluginsBiography = createPlugins(
    [
        createParagraphPlugin(),
        createBlockquotePlugin(),

        createHeadingPlugin(),

        createItalicPlugin(),

        createSoftBreakPlugin(CONFIG.softBreak),
        createExitBreakPlugin(CONFIG.exitBreak),
        createNormalizeTypesPlugin(CONFIG.forceLayout),

        createDeserializeDocxPlugin(),
    ],
    {
        components: bioComponents,
    }
)

export const plugins = createPlugins(
    [
        createParagraphPlugin(),
        createBlockquotePlugin(),
        // createTodoListPlugin(),
        createHeadingPlugin(),
        createImagePlugintest({
            // options: {
            //     uploadImage: imageUpload,
            // },
        }),
        createMediaEmbedPlugin(),
        createItalicPlugin(),
        createKbdPlugin(),
        createNodeIdPlugin(),
        createDndPlugin(),
        // createIndentPlugin(CONFIG.indent),
        // createAutoformatPlugin(CONFIG.autoformat),
        // createResetNodePlugin(CONFIG.resetBlockType),
        createSoftBreakPlugin(CONFIG.softBreak),
        createExitBreakPlugin(CONFIG.exitBreak),
        createNormalizeTypesPlugin(CONFIG.forceLayout),
        // createTrailingBlockPlugin(CONFIG.trailingBlock),
        // createSelectOnBackspacePlugin(CONFIG.selectOnBackspace),
        // createComboboxPlugin(),
        // createMentionPlugin(),
        // createDeserializeMdPlugin(),
        // createDeserializeCsvPlugin(),
        createDeserializeDocxPlugin(),
        // createJuicePlugin(),
        // trippin'
    ],
    {
        components,
    }
)

export const draftPlugins = createPlugins(
    [
        createParagraphPlugin(),
        createBlockquotePlugin(),
        createHeadingPlugin(),
        createMediaEmbedPlugin(),
        createItalicPlugin(),
    ],
    {
        components: draftComponents,
    }
)

// These are for ReadObituaryPlate
let componentshtml = createPlateUI({
    [ELEMENT_EXCALIDRAW]: ExcalidrawElement,
    [ELEMENT_H1]: withProps(StyledElement, {
        as: 'h6',
    }),
    [ELEMENT_H2]: withProps(StyledElement, {
        as: 'h6',
    }),
    [ELEMENT_H3]: withProps(StyledElement, {
        as: 'h6',
    }),
    [ELEMENT_H4]: withProps(StyledElement, {
        as: 'h6',
    }),
    [ELEMENT_H5]: withProps(StyledElement, {
        as: 'h6',
    }),
    [ELEMENT_H6]: withProps(StyledElement, {
        as: 'h6',
    }),
    [ELEMENT_PARAGRAPH]: withProps(StyledElement, {
        as: 'p',
    }),
    [ELEMENT_VIDEO]: withProps(StyledElementVideo, {
        as: 'video',
        // src: 'https://firebasestorage.googleapis.com/v0/b/minning-f44b2.appspot.com/o/videos%2Fvideos_35bb?alt=media&token=53ffa40a-fbe0-4871-8d44-92964646fb58',
        controls: true,
        // controlsList: 'nodownload noplaybackrate',
        // disablePictureInPicture: true,
        // Classname used to inject src
        className: c('obitVid', s.obituaryVideo),
        onContextMenu: (e) => e.preventDefault(),
    }),
    // customize your components by plugin key
})

export const pluginshtml = createPlugins(
    [
        createParagraphPlugin(),
        createBlockquotePlugin(),
        createHeadingPlugin(),
        createImagePlugintest({
            // options: {
            //     uploadImage: imageUpload,
            // },
        }),

        createMediaEmbedPlugin(),

        createItalicPlugin(),

        createKbdPlugin(),
        createNodeIdPlugin(),
        createDndPlugin(),

        createSoftBreakPlugin(CONFIG.softBreak),
        createExitBreakPlugin(CONFIG.exitBreak),
        createNormalizeTypesPlugin(CONFIG.forceLayout),

        createDeserializeDocxPlugin(),
    ],
    {
        components: componentshtml,
    }
)

export const comp = components
