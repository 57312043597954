/*
 * Dev tools controller.
 * show / hide dev tool controls (for grid overlay button)
 * ctrl & K toggles controls
 */

import { useKeyDown } from '../../../hooks/useKeyDown'
import { useLocalStorage } from '../../../hooks/useLocalStorage'

import { GridOverlay } from './GridOverlay'

const LOCAL_STORAGE_KEY_VISIBLE = '_devtoolsActive'

export const Devtools = () => {
    const [isVisible, setVisible] = useLocalStorage(
        LOCAL_STORAGE_KEY_VISIBLE,
        false
    )

    const handleKeys = () => {
        setVisible(!isVisible)
    }

    useKeyDown('g', handleKeys, true)

    return isVisible ? <GridOverlay /> : null
}
