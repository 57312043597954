// Firebase imports
import { Timestamp } from 'firebase/firestore'
import React from 'react'
import { PlateObj } from '../types/types'

// Creates date of format DD.MM.YYYY
const _createDate = (date: Date) => {
    let formatted_Date: string = ''
    const day = date.getDate()
    const month = date.getMonth() + 1
    const year = date.getFullYear()
    if (day < 10) {
        formatted_Date += `0${day}`
    } else {
        formatted_Date += `${day}`
    }
    formatted_Date += '.'
    if (month < 10) {
        formatted_Date += `0${month}`
    } else {
        formatted_Date += `${month}`
    }
    formatted_Date += `.${year}`

    return formatted_Date
}

const _createTime = (date: Date) => {
    let formattedTime = ''
    const hours = date.getUTCHours()
    const minutes = date.getUTCMinutes()
    if (hours < 10) formattedTime += `0${hours}`
    else formattedTime += `${hours}`
    formattedTime += ':'
    if (minutes < 10) formattedTime += `0${minutes}`
    else formattedTime += `${minutes}`
    return formattedTime
}

const _addTime = (date: Date) => {
    const numHours = date.getUTCHours()
    const numMinutes = date.getUTCMinutes()
    // const numSeconds = date.getUTCSeconds()
    let formattedTime = ' - kl. '
    if (numHours < 10) {
        formattedTime += `0${numHours}`
    } else {
        formattedTime += `${numHours}`
    }
    formattedTime += ':'
    if (numMinutes < 10) {
        formattedTime += `0${numMinutes}`
    } else {
        formattedTime += `${numMinutes}`
    }
    // formattedTime += ':'
    // if (numSeconds < 10) {
    //     formattedTime += `0${numSeconds}`
    // } else {
    //     formattedTime += `${numSeconds}`
    // }

    return formattedTime
}

const helperFunctions = () => {
    return {
        //Function that returns the formatted date
        getDate: (d: number | Timestamp | Date | string, addTime = false) => {
            const months = [
                'janúar',
                'febrúar',
                'mars',
                'apríl',
                'maí',
                'júní',
                'júlí',
                'ágúst',
                'september',
                'október',
                'nóvember',
                'desember',
            ]
            let date: Date
            let formattedDate = ''
            let formattedTime = ''
            // algolia return d as number
            if (typeof d === 'number') {
                date = new Date(d)
            }
            // Condolence date can be both Timestamp or Date
            else if (d instanceof Date) {
                date = d
            } else if (typeof d === 'string') {
                const fullDateObj = d.split('.')
                const monthIndex = parseInt(fullDateObj[1]) - 1
                const month = months[monthIndex]

                formattedDate = `${fullDateObj[0]}. ${month} ${fullDateObj[2]}`

                return formattedDate + formattedTime
            }
            // Firestore returns Timestamp
            else {
                date = new Date(d.seconds * 1000)
            }

            formattedDate =
                date.getUTCDate() +
                '. ' +
                months[date.getUTCMonth()] +
                ' ' +
                date.getUTCFullYear()

            if (addTime) {
                formattedTime = _addTime(date)
            }

            return formattedDate + formattedTime
        },
        getFancyDateFormat: (d: Timestamp | number | Date) => {
            let date: Date
            // algolia return d as number
            if (typeof d === 'number') {
                date = new Date(d)
            } else if (d instanceof Date) {
                date = d
            } else {
                date = new Date(d.seconds * 1000)
            }
            let formattedDate = _createDate(date)

            return formattedDate
        },
        getFancyTimeFormat: (d: Timestamp | number | Date) => {
            let date: Date
            // algolia return d as number
            if (typeof d === 'number') {
                date = new Date(d)
            } else if (d instanceof Date) {
                date = d
            } else {
                date = new Date(d.seconds * 1000)
            }
            let formattedTime = _createTime(date)

            return formattedTime
        },
        getCharCount: (field: string) => {
            try {
                const strippedField = field.replace(/<[^>]+>/g, '')
                return strippedField.length
            } catch (error) {
                return 0
            }
        },
        shortendBio: (html: JSX.Element | JSX.Element[]) => {
            let ret: JSX.Element
            let fallback: JSX.Element

            if (Array.isArray(html)) {
                for (let i = 0; i < html.length; i++) {
                    const element = html[i]
                    if (element.type === 'p') {
                        if (element.props.children) {
                            ret = element
                            break
                        }
                    } else {
                        if (element.props.children) {
                            if (!fallback?.props?.children) {
                                fallback = React.createElement(
                                    'p',
                                    { className: 'slate-p' },
                                    element.props.children
                                )
                            }
                        }
                    }
                }
            } else {
                ret = html.type === 'p' && html

                if (!ret) {
                    ret = html.type === 'h6' && html
                }
            }

            if (ret) {
                return React.createElement('span', null, ret.props.children)
            }
            if (fallback)
                return React.createElement(
                    'span',
                    null,
                    fallback.props.children
                )
        },
        // Form checks and page funcitons
        checkPlateForms: (plateText: string | any) => {
            const reducer = (prevVal: Array<PlateObj>, currVal: PlateObj) => {
                const currText = currVal.children[0].text.replace(' ', '')
                if (currText.length > 0) {
                    prevVal.push(currVal)
                }
                return prevVal
            }
            const bioList = plateText?.reduce(reducer, []) || []
            return bioList.length > 0
        },
        generateArrayOfYears() {
            var max = new Date().getFullYear()
            var min = 1800
            var years = []

            for (var i = max; i >= min; i--) {
                years.push({ value: i.toString(), label: i.toString() })
            }
            return years
        },
        orderFunerals: (memories: Array<any>) => {
            const todayList = []
            const tomorrowList = []
            const upcomingList = []
            const today = new Date()
            today.setHours(0, 0, 0, 0)

            memories.forEach((mem) => {
                const funeralDate: Date = new Date(
                    mem.announcements.funeral.funeralDate as number
                )
                funeralDate.setHours(0, 0, 0, 0)
                if (funeralDate.getTime() - today.getTime() === 0) {
                    todayList.push(mem)
                } else if (
                    (funeralDate.getTime() - today.getTime()) / 86400000 ===
                    1
                ) {
                    tomorrowList.push(mem)
                } else {
                    upcomingList.push(mem)
                }
            })

            return [todayList, tomorrowList, upcomingList]
        },
    }
}
export default helperFunctions()
