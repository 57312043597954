import {
    // ELEMENT_H1,
    ELEMENT_H6,
    ELEMENT_PARAGRAPH,
    withPlaceholders,
} from '@udecode/plate'

export const withStyledPlaceHolders = (components: any) =>
    withPlaceholders(components, [
        {
            key: ELEMENT_PARAGRAPH,
            placeholder: 'Skrifaðu efnisgrein...',
            hideOnBlur: true,
        },
        {
            key: ELEMENT_H6,
            placeholder: 'Fyrirsögn...',
            hideOnBlur: false,
        },
    ])
