// Firebase imports
import { db } from '../firebase/initFirebase'
import {
    doc,
    getDoc,
    updateDoc,
    arrayUnion,
    arrayRemove,
    DocumentData,
} from 'firebase/firestore'
import {
    deleteObject,
    FirebaseStorage,
    getStorage,
    ref,
} from '@firebase/storage'
// Services&Helper functions
import del from './delete'
import imageHelperFunctions from '../utils/imageHelperFunctions'
import memoryFormHelperFunctions from '../utils/memoryFormHelperFunctions'
// Types
import { ObitFormData, MemFormData, GalleryImage } from '../types/formTypes'
import { Memory, Obituary, Settings, User, NewObit } from '../types/types'
import { DocumentSnapshot, DocumentReference } from 'firebase/firestore'
import helperFunctions from '../utils/helperFunctions'
import { PlateObj } from '../types/types'
import { ELEMENT_VIDEO } from '../components/components/plate/Plate'

const _mainImgChangeHandler = async (
    storage: FirebaseStorage,
    m: MemFormData,
    savedImgURL: string,
    path: string,
    mid: string
) => {
    // Check if main image has been changed
    // If no mainImg return old img or default one

    // If there are no changes to the main image return the same image
    if (m.mainImg?.url === savedImgURL) {
        return savedImgURL
    }
    // If both are empty
    else if (!m.mainImg) {
        // Check if there was an old image url and it wasn't a fallback
        const imgData = await fetch('/api/imageSelector', {
            method: 'GET',

            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
            },
        })

        const defaultImg = await imgData.json()

        if (savedImgURL && !savedImgURL.toLowerCase().includes('fallback')) {
            // remove old image
            try {
                const desertRef = ref(storage, savedImgURL)
                await deleteObject(desertRef)
            } catch (error) {
                console.error('Could not delete image', error)
            }
            // return new fallback image
            return defaultImg.img
        }
        // If no old image url, return new fallback image
        else if (!savedImgURL) {
            return defaultImg.img
        }
        // Return new fallback if old image url was fallback
        else return defaultImg.img
    }
    // If new main image
    else if (m.mainImg.type === 'file') {
        // add new to database
        const imgPath = await imageHelperFunctions.handleUpload(
            m.mainImg.file,
            path,
            mid
        )
        // Get the url of the image
        let newImg = await imageHelperFunctions.getStorageURL(imgPath)

        if (
            savedImgURL !== '' &&
            !savedImgURL.toLowerCase().includes('fallback')
        ) {
            // remove old from database
            try {
                const desertRef = ref(storage, savedImgURL)
                await deleteObject(desertRef)
            } catch (error) {
                console.error('Could not delete image', error)
            }
        }
        return newImg
    } else {
        const imgData = await fetch('/api/imageSelector', {
            method: 'GET',

            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
            },
        })

        const defaultImg = await imgData.json()
        return savedImgURL || defaultImg.img
    }
}

const _galleryChangeHandler = async (
    storage: FirebaseStorage,
    gallery: Array<GalleryImage>,
    currImages: Array<string>,
    path: string,
    mid: string
) => {
    let newGallery: Array<any> = []

    // Map through gallery and upload all images of type File: new images.
    newGallery = await Promise.all(
        gallery.map(async (i) => {
            if (i.type === 'file') {
                const imgPath = await imageHelperFunctions.handleUpload(
                    i.file,
                    path,
                    mid
                )
                // Get the url of the image
                const newImg = await imageHelperFunctions.getStorageURL(imgPath)
                return newImg
            } else if (i.type === 'url') {
                return i.url
            }
        })
    )

    // remove images that where deleted from database
    await Promise.all(
        currImages.map(async (img) => {
            if (newGallery.includes(img) === false) {
                const desertRef = ref(storage, img)
                await deleteObject(desertRef)
            }
        })
    )
    // Return new image list
    return newGallery
}

const _galleryObitChangeHandler = async (
    storage: FirebaseStorage,
    gallery: Array<GalleryImage>,
    oldGallery: Array<GalleryImage>,
    path: string,
    mid: string
) => {
    let newGallery: Array<string> = []
    let mainImage: string = ''

    // Map through gallery and upload all images of type File: new images.
    newGallery = await Promise.all(
        gallery.map(async (i) => {
            if (i.type === 'file') {
                const imgPath = await imageHelperFunctions.handleUpload(
                    i.file,
                    path,
                    mid
                )
                // Get the url of the image
                const newImg = (await imageHelperFunctions.getStorageURL(
                    imgPath
                )) as string
                if (i.mainImg) mainImage = newImg
                return newImg
            } else if (i.type === 'url') {
                if (i.mainImg) mainImage = i.url
                return i.url
            }
        })
    )

    // remove images from database that were deleted
    await Promise.all(
        oldGallery.map(async (i) => {
            if (newGallery.includes(i.url) === false) {
                const desertRef = ref(storage, i.url)
                await deleteObject(desertRef)
            }
        })
    )
    // Return new image list
    return { main: mainImage, gallery: newGallery }
}
// Add obituary to memory
const _addToMemory = async (
    oRef: DocumentReference<DocumentData>,
    mRef: DocumentReference<DocumentData>
) => {
    await updateDoc(mRef, {
        obituaries: arrayUnion(oRef),
    })
}
//Send email to editors who want to get notification
const _sendObitNotifications = async (
    oRef: DocumentReference<DocumentData>,
    obit: NewObit,
    mid: string,
    user: User
) => {
    const res = await fetch('/api/email/obituaryNotification', {
        method: 'POST',
        body: JSON.stringify({
            oRef: oRef.id,
            obituary: obit,
            mid: mid,
            sender: user.uid,
        }),
        headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
        },
    })

    if (res) {
        console.log(res)
    } else {
        console.error('Could not send emails :(')
    }
}

// Function to retrieve which shareCards we want to create
const _getCards = (newMem: any, oldMem: Memory) => {
    let cards: string[] = []
    const { announcements } = newMem
    const oldAnnouncements = oldMem.announcements
    const hasNoCards = !oldMem.shareCards

    if (newMem.profileImg !== oldMem.profileImg || hasNoCards) {
        cards.push('memory')
        announcements.deceased.text && cards.push('deceased')
        announcements.funeral.text && cards.push('funeral')
        announcements.greeting.text && cards.push('greeting')
    } else {
        if (
            announcements.deceased.text &&
            announcements.deceased.text !== oldAnnouncements.deceased.text
        ) {
            cards.push('deceased')
        }
        if (
            announcements.funeral.text &&
            announcements.funeral.text !== oldAnnouncements.funeral.text
        ) {
            cards.push('funeral')
        }
        if (
            announcements.greeting.text &&
            announcements.greeting.text !== oldAnnouncements.greeting.text
        ) {
            cards.push('greeting')
        }
    }

    return cards
}
// Update video list by removing videos that aren't still in Plate
const updateVideoList = async (data: ObitFormData) => {
    const { videos, text } = data
    // Filter for only video paragraphs
    const parsedText = JSON.parse(text as string) as PlateObj[]
    const plateVids = parsedText.filter((p) => p.type === ELEMENT_VIDEO)
    // List for videos we want to keep
    const vidsToKeep = []
    // Get hold of videos we want to remove from MUX
    const vidsToRemove = videos.filter((v) => {
        let exists = false
        plateVids.forEach((p) => {
            if (p.url === v.displayID) {
                exists = true
            }
        })

        if (!exists) return true
        else vidsToKeep.push(v)
    })
    // Async functions that remove the specific video from MUX
    await Promise.all(
        vidsToRemove.map(async (v) => {
            await del.removeFromMUX(v)
        })
    )
    // Return the videos we want to keep
    return vidsToKeep
}

const firestoreEditService = () => {
    return {
        updateSendgridId: async (user: User, id: string) => {
            try {
                const userRef = doc(db, 'users', user.uid)
                await updateDoc(userRef, {
                    sendgridId: id,
                })
            } catch (error) {
                throw new Error('Error updating user data!')
            }
        },
        editMemory: async (
            m: MemFormData,
            memory: Memory
            // defaultValues: MemFormData
            // gallery: Array<GalleryImage>
        ) => {
            try {
                // Update main image
                const storage = getStorage()
                const newMainImg: string | void = await _mainImgChangeHandler(
                    storage,
                    m,
                    memory.profileImg,
                    'memories/' + memory.mid,
                    memory.mid
                )

                // Update memory gallery
                const newImgList = await _galleryChangeHandler(
                    storage,
                    m.gallery,
                    memory.images,
                    'memories/' + memory.mid,
                    memory.mid
                )

                const { funeral } = m.announcements
                // If we removed the order of service, delete it from the storage
                if (
                    !funeral.orderOfService &&
                    memory.announcements.funeral.orderOfService
                ) {
                    const oosToDelete =
                        memory.announcements.funeral.orderOfService
                    const desertRef = ref(storage, oosToDelete)
                    await deleteObject(desertRef)
                }

                // Update announcements
                const announcements =
                    await memoryFormHelperFunctions.createAnnouncements(
                        m,
                        memory.mid
                    )

                //Create date objects
                const birth = memoryFormHelperFunctions.createDate(m.birthDate)
                const death = memoryFormHelperFunctions.createDate(m.deathDate)
                const date = memoryFormHelperFunctions.createDate(memory.date)

                // Change PlateObj[] to string (or null if empty)
                const bio = m.biography
                const bioText = bio ? JSON.stringify(bio) : null

                const memData = {
                    announcements: announcements,
                    biography: bioText,
                    birth,
                    death,
                    fullName: m.fullName,
                    images: newImgList,
                    profileImg: newMainImg,
                    date: date,
                    relatives: m.relatives || '',
                }

                // update memory fields with new values
                const mRef = doc(db, 'memories', memory.mid)
                await updateDoc(mRef, {
                    data: memData,
                    shareCards: {
                        memory: '',
                        deceased: '',
                        funeral: '',
                        greeting: '',
                    },
                    birthYear: birth.getFullYear().toString(),
                }).catch((err) =>
                    console.error('Creating memory failed: ', err)
                )

                const cards = _getCards(memData, memory)

                // @ts-ignore
                memData.birth = helperFunctions.getDate(memData.birth)
                // @ts-ignore
                memData.death = helperFunctions.getDate(memData.death)
                // @ts-ignore
                memData.shareCards = memory.shareCards

                // Update share Cards
                fetch('/api/openGraphImage', {
                    method: 'PUT',
                    body: JSON.stringify({
                        memory: memData,
                        mid: memory.mid,
                        cards,
                    }),
                    headers: {
                        'Content-Type': 'application/json',
                    },
                })

                return { profileImg: newMainImg, images: newImgList }
            } catch (error) {
                console.error(error)
            }
        },
        updateMemoriesObits: async (obits: Array<string>, memory: Memory) => {
            // Get refrences for all the obits in a list
            const obitRefs = obits.map((oid) => doc(db, 'obituaries', oid))
            // update the memory with the new list of refrences
            try {
                const memoryRef = doc(db, 'memories', memory.mid)
                await updateDoc(memoryRef, {
                    obituaries: obitRefs,
                })
            } catch (error) {
                throw new Error('Error updating user data!')
            }
        },
        editObituary: async (
            data: ObitFormData,
            oldData: ObitFormData,
            user: User = null,
            mid: string = null,
            publish: boolean = false
        ) => {
            try {
                const storage = getStorage()
                const oid = oldData.oid
                // get new image list
                const newImgList = await _galleryObitChangeHandler(
                    storage,
                    data.gallery,
                    oldData.gallery,
                    'obit/' + oldData.oid,
                    mid
                )

                const newVidList = await updateVideoList(data)

                // update obituary fields with new values
                const oRef = doc(db, 'obituaries', oid)

                if (!publish) {
                    await updateDoc(oRef, {
                        text: data.text,
                        mainImage: newImgList.main,
                        gallery: newImgList.gallery,
                        videos: newVidList,
                    })

                    return oid
                }
                // Publishing obituary
                const mRef = doc(db, 'memories', mid)

                const newObit = {
                    text: data.text,
                    mainImage: newImgList.main,
                    gallery: newImgList.gallery as Array<string>,
                    videos: newVidList,
                    visible: true,
                    date: new Date(),
                }

                await updateDoc(oRef, {
                    ...newObit,
                })

                // Then we add the obit to its memory and send notifications to the owners
                await Promise.all([
                    _addToMemory(oRef, mRef),
                    _sendObitNotifications(oRef, newObit, mid, user),
                ])

                return oid
            } catch (error) {
                console.error(error)

                return null
            }
        },
        editSavedObit: async (
            data: ObitFormData,
            oldData: ObitFormData,
            publish: boolean,
            mid: string,
            user?: User
        ) => {
            try {
                const { oid } = oldData
                const oRef = doc(db, 'obituaries', oid)

                const storage = getStorage()

                const [newImgList, newVidList] = await Promise.all([
                    await _galleryObitChangeHandler(
                        storage,
                        data.gallery,
                        oldData.gallery,
                        'obit/' + oid,
                        mid
                    ),
                    await updateVideoList(data),
                ])
                // const newImgList = await _galleryObitChangeHandler(
                //     storage,
                //     data.gallery,
                //     oldData.gallery,
                //     'obit/' + oid,
                //     mid
                // )

                // const newVidList = await updateVideoList(data)

                // If we are not publishing
                if (!publish) {
                    await updateDoc(oRef, {
                        text: data.text,
                        mainImage: newImgList.main,
                        gallery: newImgList.gallery,
                        videos: newVidList,
                    })

                    const newGallery = newImgList.gallery.map((i) => {
                        return {
                            type: 'url',
                            url: i,
                            mainImg: i === newImgList.main,
                        }
                    }) as GalleryImage[]

                    return {
                        oid,
                        gallery: newGallery,
                        title: data.title,
                        videos: newVidList,
                    } as ObitFormData
                }

                // Else, we update and make the obit published (visible)
                const mRef = doc(db, 'memories', mid)

                const newObit = {
                    text: data.text,
                    mainImage: newImgList.main,
                    gallery: newImgList.gallery,
                    visible: true,
                    videos: data.videos,
                    date: new Date(),
                }

                await updateDoc(oRef, {
                    ...newObit,
                })

                // Then we add the obit to its memory and send notifications to the owners
                await Promise.all([
                    _addToMemory(oRef, mRef),
                    _sendObitNotifications(oRef, newObit, mid, user),
                ])

                return oid
            } catch (error) {
                console.log('error', error)

                console.error(error)
                return null
            }
        },
        publishObit: async (oid: string, mid: string, user: User) => {
            const oRef = doc(db, 'obituaries', oid)
            const mRef = doc(db, 'memories', mid)
            const obit = await getDoc(oRef).then(
                (res) => res.data() as Obituary
            )

            await updateDoc(oRef, {
                visible: true,
                date: new Date(),
            })

            await Promise.all([
                _addToMemory(oRef, mRef),
                _sendObitNotifications(oRef, obit, mid, user),
            ])

            return oid
        },
        changeVisibility: async (
            element: any,
            elemType: string,
            mid: string = null
        ) => {
            if (elemType === 'm') {
                const mRef = doc(db, 'memories', element.id)
                await updateDoc(mRef, {
                    visible: element.visible,
                })
            } else if (elemType === 'o') {
                const oRef = doc(db, 'obituaries', element.oid)
                await updateDoc(oRef, {
                    visible: element.visible,
                })
            } else {
                const cRef = doc(db, `memories/${mid}/condolences`, element.id)
                await updateDoc(cRef, {
                    visible: element.visible,
                })
            }
        },
        sendInvite: async (mid: string, email: string) => {
            const mRef = doc(db, 'memories', mid)
            await updateDoc(mRef, {
                invitedEmails: arrayUnion(email),
            })
        },
        removeInvite: async (mid: string, email: string) => {
            const mRef = doc(db, 'memories', mid)

            await updateDoc(mRef, {
                invitedEmails: arrayRemove(email),
            }).catch((err) => console.error(err))
        },
        appendMemoryToUser: async (mid: string, uid: string) => {
            const mRef = doc(db, 'memories', mid)
            const uRef = doc(db, 'users', uid)

            return await updateDoc(uRef, {
                memories: arrayUnion(mRef),
            })
                .then(() => true)
                .catch((err) => console.error(err))
        },
        appendObitToUsersBookmark: async (oid: string, uid: string) => {
            const oRef = doc(db, 'obituaries', oid)
            const uRef = doc(db, 'users', uid)

            await updateDoc(uRef, {
                bookmarks: arrayUnion(oRef),
            })
                .then(() => true)
                .catch((err) => console.error(err))
            return oRef
        },
        removeBookmarkFromUser: async (oid: string, uid: string) => {
            const oRef = doc(db, 'obituaries', oid)
            const uRef = doc(db, 'users', uid)

            await updateDoc(uRef, {
                bookmarks: arrayRemove(oRef),
            })
                .then(() => true)
                .catch((err) => console.error(err))
            return oRef
        },
        appendUserToEditors: async (mid: string, uid: string) => {
            try {
                // update memory fields with new values
                const mRef = doc(db, 'memories', mid)
                await updateDoc(mRef, {
                    editors: arrayUnion(uid),
                    followers: arrayRemove(uid),
                })

                return true
            } catch (error) {
                console.log(error)

                // Get user object
                const uRef = doc(db, 'users', uid)
                const userSnapshot: DocumentSnapshot<User> = await getDoc(
                    uRef as DocumentReference<User>
                )
                const userData: User = userSnapshot.data()
                const memories = userData.memories
                // Remove the memory from the user
                const updatedMems = memories.filter((m) => {
                    return m.id !== mid
                })
                // Update user's memories
                if (updatedMems.length !== memories.length) {
                    await updateDoc(uRef, {
                        memories: updatedMems,
                    })
                }
            }
        },
        editSendgridEmail: async (
            email: string,
            oldEmail: string,
            settings: Settings,
            oldSettings: Settings
        ) => {
            // Update sendGrid information

            await fetch('/api/auth/email', {
                method: 'PUT',
                body: JSON.stringify({
                    email,
                    settings,
                    oldEmail,
                    oldSettings,
                }),
                headers: {
                    'Content-Type': 'application/json',
                    Accept: 'application/json',
                },
            })
        },
    }
}
export default firestoreEditService()
