// React&NextJS
import React from 'react'
// // 3rd party libraries
// import { useForm } from 'react-hook-form'
// // Styles
// import s from './BugReport.module.scss'
// import c from 'classnames'

const BugReport = () => {
    // const [, setIsOpen] = useState(false)
    // const [isLoading, setIsLoading] = useState(false)

    // const toggleOpen = () => setIsOpen((prev) => !prev)

    // const SubmitHandler = async (data) => {
    //     setIsLoading((prev) => !prev)
    //     const res = await fetch('/api/send-bug', {
    //         method: 'POST',
    //         body: JSON.stringify({ email: email, mid: mid }),
    //         headers: {
    //             'Content-Type': 'application/json',
    //             Accept: 'application/json',
    //         },
    //     })
    // }

    return <></>

    // return (
    //     <div className={c(s.container)}>
    //         <button className={c(s.button)} onClick={toggleOpen}>
    //             Ábending
    //         </button>
    //         <form
    //             // onSubmit={handleSubmit(SubmitHandler)}
    //             className={s.form}
    //             id="mem-create-form"
    //         >
    //             <Input
    //                 placeholder="Netfang..."
    //                 type="text"
    //                 id="email"
    //                 {...register('email', {
    //                     required: {
    //                         value: true,
    //                         message: 'Netfang vantar',
    //                     },
    //                 })}
    //             />
    //         </form>
    //     </div>
    // )
}

export default BugReport
