// Services&Helper functions
import helperFunctions from './helperFunctions'
import imageHelperFunctions from './imageHelperFunctions'
// Types
import { Deceased, Funeral, Greeting } from '../types/types'
import { FuneralForm, MemFormData } from '../types/formTypes'
import { Timestamp } from 'firebase/firestore'

var replacements = {
    ð: 'd',
    Ð: 'D',
    þ: 'th',
    Þ: 'Th',
    æ: 'ae',
    Æ: 'Ae',
    ö: 'o',
    Ö: 'O',
    á: 'a',
    Á: 'A',
    é: 'e',
    É: 'E',
    í: 'i',
    Í: 'I',
    ó: 'o',
    Ó: 'O',
    ú: 'u',
    Ú: 'U',
    ý: 'y',
    Ý: 'Y',
}

const _replaceIcelandicCharacters = function (input: string) {
    for (var key in replacements) {
        input = input.replace(
            new RegExp('[' + key + ']', 'g'),
            replacements[key]
        )
    }
    return input
}

const _createFuneralText = (funeral: FuneralForm) => {
    return funeral.text || ''
}

const _getOrderOfServiceURL = async (file: File, id: string) => {
    let filePath = await imageHelperFunctions.handleUpload(
        file,
        'oos',
        id,
        true
    )
    // Get the url of the image
    const fileURL = await imageHelperFunctions.getStorageURL(filePath)
    return fileURL
}

const memoryFormHelperFunctions = () => {
    return {
        //Function that returns the formatted date of the selected exercise day
        createID: (fullName: string, birthDate: string, deathDate: string) => {
            const nameToEnglishLetters = _replaceIcelandicCharacters(fullName)
            const nameLower = nameToEnglishLetters.toLowerCase()
            let id =
                nameLower.replaceAll(' ', '-') +
                '-' +
                birthDate.split('.')[2] +
                '-' +
                deathDate.split('.')[2]

            const stripDash = () => {
                if (id.includes('--')) {
                    id = id.replaceAll('--', '-')
                    stripDash()
                }
            }
            stripDash()

            return id
        },
        createDate: (newDate: string | Timestamp) => {
            let date: Date = null
            if (typeof newDate === 'string') {
                const dateList = newDate.split('.')
                const day = parseInt(dateList[0])
                const month = parseInt(dateList[1]) - 1
                const year = parseInt(dateList[2])

                date = new Date(year, month, day, 12)
            } else {
                const dateInMS = newDate.seconds * 1000
                date = new Date(dateInMS)
            }
            return date
        },
        createFuneralDate: (newDate: string, time: string) => {
            const dateList = newDate.split('.').reverse()
            const timeList = time.split(':')
            const fullDateList = dateList.concat(timeList)
            const funeralDate = new Date(
                parseInt(fullDateList[0]), // YYYY
                parseInt(fullDateList[1]) - 1, // MM (-1 because months are indexes)
                parseInt(fullDateList[2]), // DD
                parseInt(fullDateList[3]), // KK
                parseInt(fullDateList[4]) // MM
            )
            return funeralDate
        },
        createAnnouncements: async (data: MemFormData, id: string) => {
            const announcements = data.announcements
            const funeralDate = announcements.funeral.funeralDate
            const funeralTime = announcements.funeral.funeralTime
            // Funeral publish date. Only updated if hold === NOW and it is empty
            const published =
                !announcements.funeral.published &&
                announcements.funeral.hold === 'NOW'
                    ? new Date()
                    : announcements.funeral.published
                    ? memoryFormHelperFunctions().createDate(
                          announcements.funeral.published as Timestamp
                      )
                    : null
            // If order of service, create an URL for it in firestore storage
            let orderOfService
            if (announcements.funeral.orderOfService === null) {
                orderOfService = ''
            } else if (
                typeof announcements.funeral.orderOfService === 'string'
            ) {
                orderOfService = announcements.funeral.orderOfService
            } else {
                orderOfService = await _getOrderOfServiceURL(
                    announcements.funeral.orderOfService,
                    id
                )
            }

            // Útfarartilkyninning
            const funeral: Funeral = {
                hold: announcements.funeral.hold,
                text: _createFuneralText(announcements.funeral),
                streamLink: announcements.funeral.streamLink || '',
                funeralDate: funeralDate
                    ? memoryFormHelperFunctions().createFuneralDate(
                          funeralDate,
                          funeralTime
                      )
                    : null,
                published: published,
                orderOfService: orderOfService as string,
            }
            // Þakkartilkynning
            const greeting: Greeting = {
                text: announcements.greeting.text || '',
                charity: announcements.greeting.charity || '',
                charityLink: announcements.greeting.charityLink || '',
            }
            // Andlátstilkynning
            const deceased: Deceased = {
                text: announcements.deceased?.text || '',
            }

            return { funeral, greeting, deceased }
        },
        validateDate: (
            d: string,
            validation: string,
            timeMS: number = null
        ) => {
            const dateList = d.split('.')
            const today = new Date()
            today.setHours(0, 0, 0, 0)
            if (validation === 'day') {
                const day = dateList[0]
                if (day.includes('_')) {
                    return false
                }
                const parsedDay = parseInt(day)
                if (parsedDay <= 0 || parsedDay > 31) {
                    return false
                }
                return true
            } else if (validation === 'month') {
                const month = dateList[1]
                if (month?.includes('_')) {
                    return false
                }
                const parsedMonth = parseInt(month)
                if (parsedMonth <= 0 || parsedMonth > 12) {
                    return false
                }
                return true
            } else if (validation === 'year') {
                const year = dateList[2]
                if (year?.includes('_')) {
                    return false
                }
                const parsedYear = parseInt(year)
                if (parsedYear < 1000) {
                    return false
                }
                return true
            } else if (validation === 'inFuture') {
                const todayTime = today.getTime()
                // Create date object from our date string
                const day = parseInt(dateList[0])
                const month = parseInt(dateList[1]) - 1
                const year = parseInt(dateList[2])

                const date = new Date(year, month, day)
                date.setHours(0, 0, 0, 0)
                const dateTime = date.getTime()

                return todayTime - dateTime >= 0
            } else if (validation === 'funeral') {
                let todayTime
                if (timeMS !== null) {
                    todayTime = new Date().getTime()
                } else {
                    todayTime = today.getTime()
                }
                // Create date object from our date string
                const day = parseInt(dateList[0])
                const month = parseInt(dateList[1]) - 1
                const year = parseInt(dateList[2])

                const date = new Date(year, month, day)
                let dateTime = date.getTime()
                if (timeMS !== null) dateTime += timeMS

                return dateTime - todayTime >= 0
            }
        },
        validateTime: (t: string) => {
            const timeList = t.split(':')
            const hours = parseInt(timeList[0])
            const minutes = parseInt(timeList[1])

            if (isNaN(hours) || hours > 23) return false
            if (isNaN(minutes) || minutes > 59) return false
            return true
        },
        checkDateExists: (d: string) => {
            const newDate = memoryFormHelperFunctions().createDate(d)
            const dateFormat = helperFunctions.getFancyDateFormat(newDate)
            if (d === dateFormat) return true
            else return false
        },
        lifeTimeChange: (
            dateFormat: string,
            name: string,
            otherDateFormat: string
        ) => {
            // Don't need to change when other date isn't complete
            if (otherDateFormat.includes('_')) {
                return true
            }
            // Get time of other date
            const otherDate: Date =
                memoryFormHelperFunctions().createDate(otherDateFormat)
            otherDate.setHours(0, 0, 0, 0)
            const otherDateTime = otherDate.getTime()
            // Get time of selected date
            const date = memoryFormHelperFunctions().createDate(dateFormat)
            date.setHours(0, 0, 0, 0)
            const dateTime = date.getTime()
            // Change other date if we go into either of those statements
            if (otherDateTime > dateTime && name === 'deathDate') {
                return false
            } else if (dateTime > otherDateTime && name === 'birthDate') {
                return false
            }
            return true
        },
        checkIfRecentDeath: (date: Date) => {
            var today = new Date()
            var todayTime = new Date(
                today.getFullYear(),
                today.getMonth(),
                today.getDate()
            ).getTime()

            const difference = todayTime - date.getTime()
            const differenceInDays = difference / 86400000
            const days = 28

            if (differenceInDays <= days) return true
            return false
        },
    }
}

export default memoryFormHelperFunctions()
