// React&NextJS
import React, { useContext, useState, useEffect } from 'react'
// Context&Auth
import { auth } from '../firebase/initFirebase'
import {
    signInWithEmailAndPassword,
    signInWithCustomToken,
    sendEmailVerification,
    updateEmail,
} from 'firebase/auth'
// Services&Helper functions
import firestoreReadService from '../services/read'
import firestoreWriteService from '../services/write'
import firestoreEditService from '../services/edit'
import firestoreDeleteService from '../services/delete'
// Types
import { Props, Settings, User } from '../types/types'
// Firestore
import 'firebase/firestore'

type UpdatedEmail = {
    updated: boolean
    error?: Error
}

// Create React Context element type
type AuthProviderType = {
    user: User
    setUser: Function
    logInWithToken: (token: string) => any
    sendUserEmailVerification: () => Promise<boolean | void>
    resetUserData: () => Promise<void>
    reloadEmailVerified: (token?: boolean) => void
    updateUserEmail: (
        email: string,
        settings: Settings,
        oldSettings: Settings
    ) => Promise<UpdatedEmail>
    logout: () => void
}

// Creating Context default values
const authContextDefaultValues: AuthProviderType = {
    user: null,
    setUser: () => {},
    logInWithToken: async () => false,
    sendUserEmailVerification: async () => false,
    resetUserData: async () => {},
    reloadEmailVerified: () => {},
    updateUserEmail: async () => null,
    logout: () => {},
}

// Create the context
const UserContext = React.createContext<AuthProviderType>(
    authContextDefaultValues
)

// Return the context to the user
export function useAuth() {
    return useContext(UserContext)
}

// Setting up auth provider
export function AuthProvider({ children }: Props) {
    const [user, setUser] = useState<User | null>(null)
    const [loading, setLoading] = useState<Boolean>(true)
    const [isBrowser, setIsBrowser] = useState<Boolean>(false)

    function logInWithToken(token) {
        return signInWithCustomToken(auth, token)
            .then((userCredentials) => {
                return userCredentials
            })
            .catch((error) => {
                const errorCode = error.code
                const errorMessage = error.message
                console.error(errorCode)
                console.error(errorMessage)
                return false
            })
    }

    async function updateUserEmail(
        email: string,
        settings: Settings,
        oldSettings: Settings
    ) {
        const oldEmail = auth.currentUser.email

        // await logInWithToken(token)

        return updateEmail(auth.currentUser, email)
            .then(async () => {
                // Delete old email from sendGrid and add new one
                await firestoreEditService.editSendgridEmail(
                    email,
                    oldEmail,
                    settings,
                    oldSettings
                )
                return { updated: true }
            })
            .catch((e: Error) => {
                return { updated: false, error: e }
            })
    }

    async function sendUserEmailVerification() {
        const token = await auth.currentUser.getIdToken()

        return await sendEmailVerification(auth.currentUser)
            .then(() => {
                return true
            })
            .catch(async (err) => {
                console.error(err)
                return false
            })
    }

    // Function to log user out
    function logout() {
        auth.signOut()
            .then(() => {
                setUser(null)
            })
            .catch((error) => {
                console.error(error)
            })
    }

    async function reloadEmailVerified(token: boolean = true) {
        const { currentUser } = auth

        if (token) {
            // Update the token
            // https://github.com/firebase/firebase-js-sdk/issues/2529
            await auth.currentUser
                .getIdToken(true)
                .catch((err) => console.error(err))
        }

        setUser((prev) => {
            return {
                ...prev,
                email: currentUser.email,
                emailVerified: currentUser.emailVerified,
            }
        })
    }

    async function resetUserData() {
        const userData = await firestoreReadService.getUser(user.uid)

        setUser((prev) => {
            return {
                ...prev,
                name: userData.name,
                memories: userData.memories,
                obituaries: userData.obituaries,
            }
        })
    }

    // Listener which runs every time authentication of user changes
    const onAuthChange = () => {
        // On change...
        return auth.onAuthStateChanged(async (userRes) => {
            // If there is an user
            if (userRes) {
                // Fetch data and update state

                const userData = await firestoreReadService.getUser(userRes.uid)

                var days = 0.5 // Days you want to subtract
                var date = new Date()
                var last = new Date(date.getTime() - days * 24 * 60 * 60 * 1000)

                let needsLogIn: boolean
                if (new Date(userRes.metadata.lastSignInTime) < last) {
                    needsLogIn = true
                    logout()
                } else {
                    needsLogIn = false
                }

                const phoneNum =
                    userRes.phoneNumber?.replace('+354', '') || null

                setUser({
                    uid: userRes.uid,
                    name: userData.name,
                    email: userRes.email,
                    emailVerified: userRes.emailVerified,
                    phone: phoneNum,
                    memories: userData.memories,
                    obituaries: userData.obituaries,
                    reAuth: needsLogIn,
                    bookmarks: userData.bookmarks ?? [],
                    // TODO: Biðja um aðgang
                    // requests: userData.requests || {
                    //     sent: [],
                    //     received: [],
                    // },
                })
            }
            //If no user logged in...
            else {
                // Change state to null
                setUser(null)
            }

            setLoading(false)
        })
    }

    useEffect(() => {
        const unsubscribe = onAuthChange()
        setIsBrowser(true)
        return () => {
            unsubscribe()
        }
    }, [])

    const value = {
        user,
        setUser,
        logInWithToken,
        updateUserEmail,
        sendUserEmailVerification,
        resetUserData,
        reloadEmailVerified,
        logout,
    }

    return (
        <UserContext.Provider value={value}>
            {(!loading || !isBrowser) && children}
        </UserContext.Provider>
    )
}
