// React&NextJS
import React, { useContext, useEffect, useRef, useState } from 'react'
// Context&Auth
import { UIContext } from '../../../context/UIContext'
import { useAuth } from '../../../context/UserContext'
// Services&Helper functions
import useScrollListener from '../../../hooks/useScroll'
// Styles
import s from './Header.module.scss'
import c from 'classnames'
// Components
import { AccountDropDown } from './AccountDropdown'
import { Button } from '../../core/button/Button'
import { Container } from '../../core/container/Container'
import { Hamburger } from '../../core/hamburger/Hamburger'
import { Icon } from '../../core/icon/Icon'
import { Link } from '../../core/link/Link'
import { usePlausible } from 'next-plausible'
import NavSearch from '../../components/search/NavSearch'

export const Header = ({ children }: { children: React.ReactNode }) => {
    const { uiState, setUIState } = useContext(UIContext)
    const [showBar, setShowBar] = useState(false)
    const [beWhite, setBeWhite] = useState(false)
    const { user } = useAuth()

    const headerRef = useRef<HTMLElement>(null)

    const scroll = useScrollListener()
    const plausible = usePlausible()

    // update classList of nav on scroll
    useEffect(() => {
        if (scroll.y - scroll.lastY > 0) {
            setShowBar(false)
        } else {
            setShowBar(true)
        }

        // mac bug
        if (scroll.y <= 0) {
            setShowBar(true)
        }

        if (scroll.y > 50) {
            setBeWhite(true)
        } else {
            setBeWhite(false)
        }
    }, [scroll.y, scroll.lastY])

    const account = user?.email ? (
        // is user currently on account pages?
        <>
            <AccountDropDown className={c(s.accountIcon)}>
                <Icon variant="account" />
            </AccountDropDown>
            {/* {window.location.href.indexOf('account') > -1 ? ( */}
            <AccountDropDown className={s.accountButton}>
                <Button
                    variant="arrow"
                    icon="dropdown"
                    invert={uiState.isNavOpen}
                    className={s.accountButton}
                >
                    {user?.name?.split(' ')[0]}
                </Button>
            </AccountDropDown>
            {/* ) : (
                <Button
                    variant="ghost"
                    className={s.accountButton}
                    invert={uiState.isNavOpen}
                    to="/account"
                >
                    Mínar síður
                </Button>
            )} */}
        </>
    ) : (
        <>
            <Link to="/account" className={s.accountIcon}>
                <Icon variant="account" />
            </Link>
            <Button
                variant="ghost"
                invert={uiState.isNavOpen}
                className={s.accountButton}
                to="/account"
                onClick={() => plausible('nav-mittSvaedi-button')}
            >
                Mitt svæði
            </Button>
        </>
    )

    return (
        <div className={c(s.stick)}>
            <div
                className={c(
                    s.background,
                    showBar && s.showBg,
                    beWhite && s.whiteBg
                )}
            ></div>

            <div className={s.header__nav}>{children}</div>
            <header
                className={c(
                    s.header,
                    { [s.header__open]: uiState.isNavOpen },
                    showBar && s.showHeader,
                    beWhite && s.beWhite
                )}
                ref={headerRef}
            >
                <Container className={s.header__container}>
                    <a tabIndex={0} className={s.header__skip} href="#main">
                        Skip to content
                    </a>
                    <Link
                        className={c(s.header__logo, {
                            [s.header__logo_open]: uiState.isNavOpen,
                        })}
                        tabIndex={0}
                        to="/"
                    >
                        <Icon variant="logo" />
                    </Link>

                    <NavSearch
                        redirect={'/memories'}
                        className={c(
                            s.header__search,

                            beWhite && s.header__search_outline,
                            uiState.isNavOpen && s.header__search_off
                        )}
                    />

                    <div
                        className={c(s.header__controls, {
                            [s.header__controls_open]: uiState.isNavOpen,
                        })}
                    >
                        <>
                            <Link
                                className={c(
                                    s.search_icon
                                    // uiState.isNavOpen && s.search_icon_off
                                )}
                                tabIndex={0}
                                to="/memories"
                            >
                                <Icon variant="search"></Icon>
                            </Link>

                            {account}
                        </>

                        <Hamburger
                            open={uiState.isNavOpen}
                            beWhite={beWhite}
                            onClick={() => {
                                setUIState({ isNavOpen: !uiState.isNavOpen })
                                plausible('nav-hamburger-button')
                            }}
                        />
                    </div>
                </Container>
            </header>
        </div>
    )
}
